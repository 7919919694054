<template>
  <div class="t-28 fw-7 onyx">Log Movie</div>
  <BaseBreadcrumbs :crumbs="breadcrumbs" class="m-tx4" />
  <form
    method="post"
    :action="`/movies${isNew ? '' : `/${movieId}`}`"
    class="m-tx8"
  >
    <input v-if="!isNew" type="hidden" name="_method" value="PUT">
    <div class="f">
      <MoviesPoster :movie="tmdbStore.selectedMovie" size="w154"/>
      <div class="f-v m-tx2">
        <div class="t-24 fw-6 onyx">{{ tmdbStore.selectedMovie.title }}</div>
        <div class="t-16 fw-4 onyx m-tx2">{{ tmdbStore.selectedMovie.release_date }}</div>
        <div class="t-14 fw-4 onyx w-x38 h-x40 of-y-s b-1-onyx br-2 p-x1 m-tx3">{{ tmdbStore.selectedMovie.overview }}</div>
      </div>
    </div>
    <input
      type="hidden"
      name="tmdb_movie_data"
      :value="JSON.stringify(tmdbStore.selectedMovie)"
    >
    <div v-if="isNew" class="f-ac m-bx2">
      <input type="hidden" :value="noViewingData" name="no_viewing_data">
      <input type="checkbox" v-model="noViewingData" class="input-checkbox" id="noViewing">
      <label for="noViewing" class="input-label m-lx2">No Viewing Data</label>
    </div>
    <div :class="`f-v ${noViewingData ? 'hidden-viewing-data' : ''}`">
      <div class="f-ac">
        <label for="viewingDate" class="input-label w-x30 m-rx1"> Viewed on </label>
        <input type="date" class="input-date" id="viewingDate" v-model="viewingDate" name="movie_viewing[viewing_date]">
      </div>
      <div class="f-ac m-tx1">
        <label for="location" class="input-label w-x30 m-rx1"> Location </label>
        <InputsTextAutocomplete name="movie_viewing[location]" v-model="location" :options="tmdbStore.prefills.location" id="location" />
      </div>
      <div class="f-ac m-tx1">
        <label for="proposer" class="input-label w-x30 m-rx1"> Proposer </label>
        <InputsTextAutocomplete name="movie_viewing[proposer]" v-model="proposer" :options="tmdbStore.prefills.proposer" id="proposer" />
      </div>
      <div class="f-ac m-tx1">
        <label for="attendees" class="input-label w-x30 m-rx1"> Attendees </label>
        <InputsTextAutocomplete name="movie_viewing[attendees][]" v-model="attendees" :options="tmdbStore.prefills.attendees" :is-multi="true" id="attendees" />
      </div>
      <div class="f-ac m-tx1">
        <label for="notes" class="input-label w-x30 m-rx1"> Notes </label>
        <textarea class="input-text h-x20" id="notes" v-model="notes" name="movie_viewing[notes]" />
      </div>
    </div>
    <div class="f-ac m-tx4">
      <input class="input-text w-x50 m-rx2" name="movie_club_log_key" placeholder="Movie Club Log Passkey">
      <button type="submit" class="button"> Submit </button>
    </div>
  </form>
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import { ref, computed, onMounted } from 'vue';

  import { useTmdbStore } from '../../stores/tmdb_store.js';
  const tmdbStore = useTmdbStore();

  const route = useRoute();
  const movieId = computed(() => route.params['id']);
  const isNew = computed(() => !movieId.value);

  // Breadcrumbs
  const onCrumbClick = () => {
    tmdbStore.selectMovie(null);
  };
  const breadcrumbs = computed(() => {
    if (isNew.value) {
      return [
        { label: 'Movie Club Log', path: '/movies' },
        { label: 'Select Movie', clickMethod: onCrumbClick },
      ];
    } else {
      return [
        { label: 'Movie Club Log', path: '/movies' },
        { label: tmdbStore.selectedMovie.title, path: `/movies/${tmdbStore.selectedMovie.id}` },
      ];
    }
  });

  // Viewing Info
  const noViewingData = ref(false);
  const viewing = computed(() => tmdbStore.selectedMovie.viewing);
  const location = computed({
    get: () => viewing.value.location,
    set: (value) => tmdbStore.updateViewing({ key: 'location', value }),
  });
  const proposer = computed({
    get: () => viewing.value.proposer,
    set: (value) => tmdbStore.updateViewing({ key: 'proposer', value }),
  });
  const attendees = computed({
    get: () => viewing.value.attendees,
    set: (value) => tmdbStore.updateViewing({ key: 'attendees', value }),
  });
  const notes = computed({
    get: () => viewing.value.notes,
    set: (value) => tmdbStore.updateViewing({ key: 'notes', value }),
  });


  // Viewing Date
  const viewingDate = ref(null);
  onMounted(() => {
    const date = viewing.value.date;
    const dateParsed = date ? new Date(date) : new Date();
    const dateParts = dateParsed.toLocaleDateString().split('/').map((v) => {
      return v.length === 1 ? `0${v}` : v;
    });
    viewingDate.value = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  });
</script>