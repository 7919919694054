<!-- This whole thing is a very basic version of MoviesSearch. Could probably stand to be DRY'd up -->
<template>
  <div class="t-24 fw-7"> Select Movie </div>
  <div class="f m-tx6">
    <div class="pos-rel">
      <span class="material-symbols-outlined pos-abs left-4 top-4"> search </span>
      <input class="input-text w-x50 p-lx8" v-model="titleQuery" placeholder="Title" @keyup="onKeyUp">
    </div>
    <button :class="`button w-x15 m-lx2 ${!titleQuery.trim() ? ' disabled' : ''}`" @click="performSearch">Search</button>
  </div>
  <div v-if="selectedMovie" class="f m-tx2">
    <MoviesPoster
      :movie="selectedMovie"
      size="w154"
    />
    <div class="f-v m-tx2">
      <div class="t-18 fw-x5">{{ selectedMovie.title + ' (' + selectedMovie.release_date.split('-')[0] + ')'}}</div>
      <div class="t m-tx3 max-h-x50 scroll-y">{{ selectedMovie.overview }}</div>
    </div>
  </div>
  <div v-else class="f-as w-100p h-x50 bg-gray b-1-dark-gray br-4 scroll-x m-tx3">
    <div v-for="result in results.filter(r => r.poster_path)" class="f-v h-x50">
      <MoviesPoster 
        :movie="result"
        :is-link="true"
        @click="selectMovie(result)"
      />
      <div class="t-12 max-w-x25 ellipsis m-lx2">{{ result.title }}</div>
      <div class="t-12 max-w-x25 ellipsis m-lx2">({{ result.release_date.split('-')[0] }})</div>
    </div>
  </div>
  <div class="f-je w-100p m-tx4">
    <button class="button m-rx1" @click="clearSelection">Clear Selection</button>
    <button :class="`button ${selectedMovie ? '' : 'disabled'}`" @click="confirm">Select</button>
  </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useModalStore } from '../../../stores/modal_store.js';
  import axios from 'axios';

  const modalStore = useModalStore();

  // Selection
  const selectedMovie = ref(null);
  const selectMovie = (movie) => {
    selectedMovie.value = movie;
  }
  const confirm = () => {
    // Calling addMovieToOption from PollsNew that's passed in via modalStore data
    // Kind of bad practice, but whatever. It works.
    modalStore.data.addMovieToOption(modalStore.data.idx, selectedMovie.value);
    modalStore.closeModal();
  };
  const clearSelection = () => {
    modalStore.data.addMovieToOption(modalStore.data.idx, null);
    modalStore.closeModal();
  };

  // Searching
  const titleQuery = ref('');
  const results = ref([]);
  const performSearch = () => {
    selectedMovie.value = null;
    let url = `/external_api/tmdb/search?title=${titleQuery.value}`;
    axios.get(url)
      .then(({ data }) => {
        results.value = data.results;
      })
  };

  // Key Codes
  const onKeyUp = (e) => {
    if (e.keyCode === 13) {
      performSearch();
    }
  };

  onMounted(() => {
    if (modalStore.data.movie) selectedMovie.value = modalStore.data.movie;
  });
</script>