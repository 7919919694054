import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/pages/home.vue';
import BillSplitterIndex from '../components/bill-splitter/Index.vue';
import MidpointIndex from '../components/midpoint/Index.vue';
import MoviesMenu from '../components/movies/Menu.vue';
import MoviesIndex from '../components/movies/Index.vue';
import MoviesShow from '../components/movies/Show.vue';
import MoviesNew from '../components/movies/New.vue';
import MoviesEdit from '../components/movies/Edit.vue';
import MoviesGraphs from '../components/movies/Graphs.vue';
import PollsIndex from '../components/polls/Index.vue';
import PollsNew from '../components/polls/New.vue';
import PollsShow from '../components/polls/Show.vue';
import PollsVote from '../components/polls/Vote.vue';
import ResumeMusic from '../components/resume/music/Index.vue';

let rootComponent = MoviesMenu;
if (window.location.hostname === 'www.willthecomposer.com') rootComponent = ResumeMusic;

const routes = [
  { path: '/', component: rootComponent },
  { path: '/apps/bill_splitter', component: BillSplitterIndex },
  { path: '/apps/midpoint', component: MidpointIndex },
  { path: '/pages/home', component: Home },
  { path: '/movies/menu', component: MoviesMenu },
  { path: '/movies', component: MoviesIndex },
  { path: '/movies/:id', component: MoviesShow },
  { path: '/movies/new', component: MoviesNew },
  { path: '/movies/:id/edit', component: MoviesEdit },
  { path: '/movies/graphs', component: MoviesGraphs },
  { path: '/polls', component: PollsIndex },
  { path: '/polls/new', component: PollsNew },
  { path: '/polls/:id', component: PollsShow },
  { path: '/polls/:poll_id/poll_votes/new', component: PollsVote },
  { path: '/resume/music', component: ResumeMusic },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;