<template>
  <div class="sidebar">
    <div :class="itemListClasses">
      <a class="t fw-600 f-ac gray white-hover hover-translate c-p no-ul m-bx4" v-for="item in items" :href="item.href">
        <span class="material-symbols-outlined m-rx1"> {{ item.icon }} </span>
        <span v-if="!isMobile"> {{ item.name }} </span>
      </a>
    </div>
  </div>
</template>

<script>
  import { ref, computed } from 'vue';
  import { useScreens } from '../composables/screens.js';

  export default {
    setup() {
      const { isMobile } = useScreens();
      const items = ref([
        { name: 'Movie Club', icon: 'movie', href: '/movies/menu' },
        { name: 'Polls', icon: 'how_to_vote', href: '/polls' },
        { name: 'Bill Splitter', icon: 'receipt_long', href: '/apps/bill_splitter' },
        { name: 'Midpoint', icon: 'map', href: '/apps/midpoint' },
      ]);

      const itemListClasses = computed(() => {
        let classes = 'h-100p';
        if (isMobile.value) {
          return classes + ' m-x2 f-sa';
        } else {
          return classes + ' m-x4';
        }
      });

      return {
        items,
        isMobile,
        itemListClasses,
      }
    }
  }
</script>