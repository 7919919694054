<template>
  <div class="t-24 fw-7 m-bx4"> Upload - Caviar </div>
  <div class="t-16 h-x6 f-ac">
    <span>1. Click on the "</span>
    <span class="material-symbols-outlined t-18">receipt</span>
    <span>Orders" section in the sidebar</span>
  </div>
  <div class="t-16 h-x6 f-ac">
    <span>2. Click "</span>
    <span class="material-symbols-outlined t-18">receipt</span>
    <span>View Receipt" on all relevant orders</span>
  </div>
  <div class="t-16 h-x6 f-ac">3. Press Ctrl+A then Ctrl+C to copy contents of receipt</div>
  <div class="t-16 h-x6 f-ac">4. Ctrl+V in text input below to paste and parse receipt</div>
  <div class="t-16 f-ac m-tx2" v-for="receipt in receipts">
    <template v-if="receipt">
      <span class="material-symbols-outlined seafoam m-rx1">new_releases</span>
      <span>{{ receipt.title }}</span>
    </template>
    <template v-else>
      <span class="material-symbols-outlined pink m-rx1">error</span>
      <span>Upload Unsuccesful</span>
    </template>
  </div>
  <input
    class="input-text m-tx4"
    placeholder="Paste 'receipt' here..."
    @keypress.prevent
    @paste="handlePaste"
  >
  <div class="f-je w-100p m-tx4">
    <button :class="`button ${buttonClasses}`" @click="addReceipts">Add Receipts</button>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useBillSplitterStore } from '../../../stores/bill_splitter_store';
  import { useModalStore } from '../../../stores/modal_store';

  const billSplitterStore = useBillSplitterStore();
  const modalStore = useModalStore();

  const receipts = ref([]);

  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('Text');

    const parsedReceipt = parseReceipt(pastedData);
    receipts.value.push(parsedReceipt);
  };

  const parseReceipt = (receipt) => {
    const receiptParts = receipt.split('\n');
    if (receiptParts[0] !== 'Caviar') return null;

    const restaurant = receiptParts[4].trim();
    const date = receiptParts[2].split(',')[1].trim();
    
    const parsedReceipt = { title: restaurant + ': ' + date };

    const detailsStartIdx = 7;
    const detailsEndIdx = receiptParts.findIndex((detail) => detail === 'Subtotal');
    const details = receiptParts.slice(detailsStartIdx, detailsEndIdx);
    parsedReceipt.items = parseItems(details);
    if (!parsedReceipt.items) return null;

    const totalIdx = receiptParts.findIndex((detail) => detail === 'Total') + 1;
    parsedReceipt.totalSpent = parseFloat(receiptParts[totalIdx].split('$')[1]);
    
    return parsedReceipt;
  };

  const parseItems = (details) => {
    let itemRegex = /^\d+x/; // This means the line is starting with, for instance, "1x..."
    const chunks = [];
    let chunk = [];
    for(let i = 0; i < details.length; i++) {
      const detail = details[i].replace(/×/g, "x"); // Caviar is giving me '×'. Replace it with a proper 'x';
      if (itemRegex.test(detail)) {
        if (chunk.length) chunks.push(chunk);
        chunk = [];
      }
      chunk.push(detail.trim());
    };
    chunks.push(chunk);

    return chunks.map((chunk) => {
      const numItems = parseInt(chunk[0].split('x')[0]);
      let name = chunk[0].split('x')[1];
      if (numItems > 1) name += ` [${numItems}]`;
      const priceDetail = chunk.find(detail => detail.startsWith('$'));
      const price = parseFloat(priceDetail.split('$')[1]);
      return { name, price };
    });
  };

  // Submission
  const validReceipts = computed(() => receipts.value.filter(r => r));
  const buttonClasses = computed(() => validReceipts.value.length > 0 ? '' : 'disabled');
  const addReceipts = () => {
    validReceipts.value.forEach((receipt) => {
      billSplitterStore.addTab(receipt);
    });
    billSplitterStore.removeEmptyTabs();
    modalStore.closeModal();
  };
</script>