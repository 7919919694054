<template>
  <div class="t-28 fw-7 onyx">Midpoint</div>
  <div :class="`${isMobile ? 'f-v' : 'f'} m-tx8`">
    <MidpointControls :class="isMobile ? 'm-bx4' : 'm-rx4'" />
    <MidpointMap :map-id="mapId" />
  </div>
</template>

<script setup>
  import { useScreens } from '../../composables/screens.js';

  const { mapId } = defineProps(['mapId']);
  const { isMobile } = useScreens();
</script>