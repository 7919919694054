<template>
  <div class="t-24 fw-7"> {{ fullTitle }} </div>
  <div :class="`${isMobile ? 'f-v max-h-x80 scroll-y' : 'f'} m-tx2`">
    <MoviesPoster
      :movie="movie"
      size="w185"
    />
    <div class="f-v m-tx2">
      <div class="t">{{ movie.overview }}</div>
    </div>
  </div>
  <div class="f-je w-100p m-tx1">
    <a :href="trailerUrl" target="_blank" :class="`button h-20 ${trailerUrl ? '' : 'disabled'}`">
      <span>View Trailer</span>
      <span class="material-symbols-outlined m-lx1">movie</span>
    </a>
    <a :href="moreInfoUrl" target="_blank" class="button h-20 m-lx2">
      <span>More Info</span>
      <span class="material-symbols-outlined m-lx1">info</span>
    </a>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useModalStore } from '../../../stores/modal_store.js';
  import { useScreens } from '../../../composables/screens.js';
  import axios from 'axios';

  const modalStore = useModalStore();
  const { isMobile } = useScreens();

  const movie = computed(() => modalStore.data.movie);
  const fullTitle = computed(() => {
    const date = movie.value.release_date.split('-')[0];
    return movie.value.title + ' (' + date + ')';
  });

  const moreInfoUrl = computed(() => {
    return `https://www.themoviedb.org/movie/${movie.value.id}`;
  });

  const trailer = ref(null);
  const trailerUrl = computed(() => {
    if (trailer.value?.site === 'YouTube') {
      return `https://www.youtube.com/watch?v=${trailer.value.key}`;
    } else if (trailer.value?.site === 'Vimeo') {
      return `https://vimeo.com/${trailer.value.key}`;
    }
  });
  const getVideos = () => {
    let url = `/external_api/tmdb/${movie.value.id}/videos`;
    axios.get(url)
      .then(({ data }) => {
        const trailers = data.results.filter(video => video.type === 'Trailer');
        trailer.value = trailers[0];
      })
  }

  onMounted(() => {
    // TODO: This is inefficient to hit this every time the modal opens.
    // Should probably just save on the movie itself :thinking:
    getVideos();
  });
</script>