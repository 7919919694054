<template>
  <div class="t-28 fw-7 onyx">New Poll</div>
  <BaseBreadcrumbs :crumbs="[{ path: '/polls', label: 'Polls' }]" class="m-tx4" />
  <form action="/polls" method="post" class="f-v m-tx8">
    <InputsToggle
      v-model="isForMovieClub"
      label="Poll is for Movie Club"
    />
    <input
      type="text"
      name="poll[title]"
      v-model="title"
      class="input-text w-x80 m-tx6"
      placeholder="Poll title..."
    >
    <div v-for="(option, idx) in options" :class="idx === 0 ? 'f m-tx6' : 'f m-tx2'">
      <input
        type="text"
        :name="`poll[options][${idx}][name]`"
        v-model="option.name"
        :class="`input-text ${isForMovieClub ? 'w-x60' : 'w-x70'} ${option.movie ? 'bg-gray' : ''}`"
        :placeholder="`Option ${idx + 1}...`"
        :readonly="option.movie"
      >
      <input
        v-if="isForMovieClub && option.movie"
        type="hidden"
        :name="`poll[options][${idx}][movie]`"
        :value="JSON.stringify(option.movie)"
      >
      <button type="button" class="button w-x8 fw-7 m-lx2" @click="removeOption(idx)">
        <span class="material-symbols-outlined"> close </span>
      </button>
      <button v-if="isForMovieClub" type="button" class="button w-x8 fw-7 m-lx2" @click="openMovieSelectorModal(idx)">
        <span class="material-symbols-outlined"> open_in_new </span>
      </button>
    </div>
    <button type="button" class="button w-x28 m-tx2" @click="addOption">Add Option</button>
    <button type="submit" :class="submitButtonClasses">Submit</button>
  </form>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useModalStore } from '../../stores/modal_store.js';

  const modalStore = useModalStore();

  const isForMovieClub = ref(true);
  const title = ref('');
  const options = ref([{ name: '' }, { name: '' }, { name: '' }]);

  const valid = computed(() => {
    const optionsAreValid = options.value.length > 1 && options.value.every(o => o.name.length > 0);
    return title.value.length > 0 && optionsAreValid;
  });

  const addOption = () => {
    options.value.push({ name: '' });
  };
  const removeOption = (idx) => {
    options.value.splice(idx, 1);
  };

  const submitButtonClasses = computed(() => {
    let classes = 'button w-x32 m-tx6';
    if (!valid.value) classes += ' disabled';
    return classes;
  });

  // Movie Club Add-on
  const addMovieToOption = (idx, movie) => {
    if (movie) {
      const name = `${movie.title} (${movie.release_date.split('-')[0]})`;
      options.value[idx].name = name;
    } else {
      options.value[idx].name = '';
    }
    options.value[idx].movie = movie;
  };
  const openMovieSelectorModal = (idx) => {
    const movie = options.value[idx].movie;
    modalStore.updateModal({
      componentName: 'PollsModalsMovieSelector',
      data: { idx, movie, addMovieToOption },
    });
  };
</script>