<template>
  <div class="t-28 fw-7 onyx"> {{ props.poll.title }} </div>
  <BaseBreadcrumbs :crumbs="[{ label: 'Polls', path: '/polls' }]" class="m-tx4" />
  <form :action="`/polls/${props.poll.id}/poll_votes`" method="post" class="f-v m-tx4">
    <input
      class="input-text w-x60"
      type="text"
      name="poll_votes[voter_name]"
      placeholder="Your name here..."
    >
    <input
      class="input-text w-x60 m-tx4"
      type="text"
      name="poll_votes[pin]"
      placeholder="PIN [optional]"
    >
    <draggable
      v-model="order"
      item-key="id"
      class="m-tx4"
    >
      <template v-slot:item="{ element }">
        <div :class="optionDraggableClasses">
          <input
            type="hidden"
            name="poll_votes[order][]"
            :value="element.id"
          >
          <div class="f">
            <div class="f-v m-lx1">
              <span class="material-symbols-outlined c-p" @click="moveUp(element)"> expand_less </span>
              <span class="material-symbols-outlined c-p" @click="moveDown(element)"> expand_more </span>
            </div>
            <div class="t-16 m-x4"> {{ element.name }} </div>
          </div>
          <button v-if="element.movie" class="button m-x2" @click.prevent="openMoviePreview(element.movie)">
            <span class="material-symbols-outlined"> info </span>
          </button>
        </div>
      </template>
    </draggable>
    <input type="submit" value="Submit" class="button w-x20 m-tx6">
  </form>
</template>

<script setup>
  import draggable from 'vuedraggable'
  import { useScreens } from '../../composables/screens.js';
  import { ref, computed, onMounted } from 'vue';
  import { useModalStore } from '../../stores/modal_store.js';

  const modalStore = useModalStore();

  const props = defineProps(['poll']);
  const { isMobile } = useScreens();

  const order = ref([]);

  onMounted(() => {
    order.value = props.poll.options.sort(() => Math.random() - 0.5);
  });

  const optionDraggableClasses = computed(() => {
    let classes = 'f-sb b-1-black br-4 m-bx2 c-p bg-gray b-s';
    if (isMobile.value) {
      return classes += ' w-x80';
    }
    return classes += ' w-x100';
  });
  const moveUp = (el) => {
    let idx;
    order.value.forEach((o, i) => {
      if (o.id === el.id) idx = i;
    })
    if (idx > 0 && idx < order.value.length) {
      const removedItem = order.value.splice(idx, 1)[0];
      order.value.splice(idx - 1, 0, removedItem);
    }
  };
  const moveDown = (el) => {
    let idx;
    order.value.forEach((o, i) => {
      if (o.id === el.id) idx = i;
    })
    if (idx >= 0 && idx < order.value.length - 1) {
      const removedItem = order.value.splice(idx, 1)[0];
      order.value.splice(idx + 1, 0, removedItem);
    }
  };

  // Movie Club Add-ons
  const openMoviePreview = (movie) => {
    modalStore.updateModal({
      componentName: 'PollsModalsMoviePreview',
      data: { movie },
    });
  };
</script>